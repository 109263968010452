import { Alert, useTheme } from '@mui/material';
import {
  CustomContentProps,
  SnackbarProvider as NotistackSnackbarProvider,
  SnackbarContent,
  closeSnackbar,
} from 'notistack';
import { forwardRef } from 'react';

type CustomOptions = {
  /**
   * If true, a close button is added to the snackbar unless `action` is also provided.
   * @default false
   */
  closeable?: boolean;
};

declare module 'notistack' {
  interface VariantOverrides {
    error: CustomOptions;
    warning: CustomOptions;
    success: CustomOptions;
    info: CustomOptions;
    default: CustomOptions;
  }
}

type CustomSnackbarProps = CustomContentProps & CustomOptions;

const CustomSnackbar = forwardRef<HTMLDivElement, CustomSnackbarProps>(
  function CustomSnackbar(
    {
      id,
      message,
      variant,
      iconVariant: _,
      hideIconVariant,
      style,
      action,
      closeable = false,
    },
    ref,
  ) {
    return (
      <SnackbarContent ref={ref} style={style}>
        <Alert
          icon={variant === 'default' || hideIconVariant ? false : undefined}
          severity={variant === 'default' ? undefined : variant}
          onClose={closeable ? () => closeSnackbar(id) : undefined}
          action={typeof action === 'function' ? action(id) : action}
          sx={{
            bgcolor: variant === 'default' ? 'background.paper' : undefined,
            borderColor: variant === 'default' ? 'grey.400' : undefined,
            color: 'text.primary',
            width: '100%',
            alignItems: 'center',
            maxWidth: 480,
          }}
        >
          {message}
        </Alert>
      </SnackbarContent>
    );
  },
);

/** Snackbar provider for `notistack`. Should be used within MUI `ThemeProvider`. */
export function SnackbarProvider({ children }: { children: React.ReactNode }) {
  const theme = useTheme();

  return (
    <NotistackSnackbarProvider
      Components={{
        success: CustomSnackbar,
        warning: CustomSnackbar,
        error: CustomSnackbar,
        info: CustomSnackbar,
        default: CustomSnackbar,
      }}
      transitionDuration={{ enter: theme.transitions.duration.standard }}
    >
      {children}
    </NotistackSnackbarProvider>
  );
}

export { useSnackbar } from 'notistack';
