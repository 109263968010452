import { useAuth } from '@evoko/api';
import { BiampLogo, Page } from '@evoko/components';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import locale from '../../locale';
import background from './assets/landing-page-background.jpg';

const LL = locale.pages.landing;

export default function LandingPage() {
  const { authenticated, loading, error, signInRedirect } = useAuth();
  const now = new Date();

  return (
    <Page
      title={LL.title}
      component="main"
      minHeight="100%"
      display="flex"
      p={{ xs: 0, sm: 1 }}
      sx={{
        backgroundImage: { xs: 'none', sm: `url("${background}")` },
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        gap={2}
        p={{ xs: 2, sm: 4 }}
        borderRadius={{ xs: 0, sm: 1 }}
        maxWidth={534}
        bgcolor="background.default"
      >
        <BiampLogo width={100} />
        <Box>
          <Typography
            variant="h0"
            component="h1"
            sx={{ wordSpacing: 99999 }}
            fontWeight={400}
          >
            {LL.title}
          </Typography>
          <Typography my={2}>{LL.subtitle}</Typography>
          {authenticated ? (
            <Button
              color="primary"
              variant="contained"
              component={RouterLink}
              to="/profile"
              size="large"
              fullWidth
            >
              {LL.profile}
            </Button>
          ) : (
            <Stack spacing={2}>
              <LoadingButton
                loading={loading}
                color="primary"
                variant="contained"
                onClick={() => signInRedirect({ errorPolicy: 'catch' })}
                size="large"
                fullWidth
              >
                {LL.signInOrRegister}
              </LoadingButton>
              <Collapse in={!!error && error.type === 'sign-in-redirect'}>
                <Alert severity="error">{LL.alerts.signInRedirectFailed}</Alert>
              </Collapse>
            </Stack>
          )}
        </Box>
        <Box
          component="ul"
          display="flex"
          alignItems="baseline"
          gap={2}
          color="text.secondary"
          p={0}
          m={0}
          sx={{ listStyle: 'none' }}
        >
          <Typography component="li">
            <Link color="inherit" underline="hover" href="https://biamp.com">
              {now.getFullYear()} Biamp®
            </Link>
          </Typography>
          <Typography component="li">
            <Link
              color="inherit"
              underline="hover"
              href="https://www.biamp.com/legal/privacy-policy"
            >
              Privacy
            </Link>
          </Typography>
          <Typography component="li">
            <Link
              color="inherit"
              underline="hover"
              component={RouterLink}
              to="/eula"
            >
              Terms of Service
            </Link>
          </Typography>
        </Box>
      </Box>
    </Page>
  );
}
