import { Role } from './generated/graphql';

type RoleSpec = {
  inherits: Role | null;
  rights: Permission[];
};

export const Permission = {
  CreateDesk: 'createDesk',
  UpdateDesk: 'updateDesk',
  DeleteDesk: 'deleteDesk',
  AssignFirmware: 'assignFirmware',
  UpdateOrganization: 'updateOrganization',
  UpdateOrganizationName: 'updateOrganizationName',
  UpdateOrganizationDomain: 'updateOrganizationDomain',
  DeleteOrganization: 'deleteOrganization',
  ActivateLicense: 'activateLicense',
  CreateOverview: 'createOverview',
  UpdateOverview: 'updateOverview',
  DeleteOverview: 'deleteOverview',
  AuthorizeOverview: 'authorizeOverview',
  CreateBuilding: 'createBuilding',
  UpdateBuilding: 'updateBuilding',
  DeleteBuilding: 'deleteBuilding',
  CreateFloor: 'createFloor',
  UpdateFloor: 'updateFloor',
  DeleteFloor: 'deleteFloor',
  CreateRoom: 'createRoom',
  UpdateRoom: 'updateRoom',
  DeleteRoom: 'deleteRoom',
  CreateInvitation: 'createInvitation',
  UpdateInvitation: 'updateInvitation',
  DeleteInvitation: 'deleteInvitation',
  UpdateMembership: 'updateMembership',
  DeleteMembership: 'deleteMembership',
  AddIDTag: 'addIDTag',
  DeleteIDTag: 'deleteIDTag',
  AddDeskBooking: 'addDeskBooking',
  EditDeskBooking: 'editDeskBooking',
  CancelDeskBooking: 'cancelDeskBooking',
  AddRoomBooking: 'addRoomBooking',
  EditRoomBooking: 'editRoomBooking',
  CancelRoomBooking: 'cancelRoomBooking',
} as const;

export type Permission = (typeof Permission)[keyof typeof Permission];

const roles: { [key in Role]: RoleSpec } = {
  [Role.Unspecified]: {
    inherits: null,
    rights: [],
  },
  [Role.User]: {
    inherits: Role.Unspecified,
    rights: [],
  },
  [Role.DeskAdmin]: {
    inherits: Role.User,
    rights: [
      Permission.CreateDesk,
      Permission.UpdateDesk,
      Permission.DeleteDesk,
      Permission.AssignFirmware,
      Permission.CreateBuilding,
      Permission.UpdateBuilding,
      Permission.DeleteBuilding,
      Permission.CreateFloor,
      Permission.UpdateFloor,
      Permission.DeleteFloor,
      Permission.AddDeskBooking,
      Permission.EditDeskBooking,
      Permission.CancelDeskBooking,
      Permission.AddRoomBooking,
      Permission.EditRoomBooking,
      Permission.CancelRoomBooking,
    ],
  },
  [Role.OrgAdmin]: {
    inherits: Role.DeskAdmin,
    rights: [
      Permission.CreateInvitation,
      Permission.UpdateInvitation,
      Permission.DeleteInvitation,
      Permission.CreateOverview,
      Permission.UpdateOverview,
      Permission.DeleteOverview,
      Permission.AuthorizeOverview,
      Permission.CreateRoom,
      Permission.UpdateRoom,
      Permission.DeleteRoom,
      Permission.UpdateOrganization,
      Permission.ActivateLicense,
      Permission.UpdateMembership,
      Permission.DeleteMembership,
      Permission.AddIDTag,
      Permission.DeleteIDTag,
    ],
  },
  [Role.Owner]: {
    inherits: Role.OrgAdmin,
    rights: [
      Permission.UpdateOrganizationName,
      Permission.UpdateOrganizationDomain,
      Permission.DeleteOrganization,
    ],
  },
};

export const isAllowed = (role: Role, permission: Permission): boolean => {
  const roleSpec: RoleSpec = roles[role];
  if (!roleSpec) {
    return false;
  }

  if (roleSpec.rights.includes(permission)) {
    return true;
  } else if (roleSpec.inherits !== null) {
    return isAllowed(roleSpec.inherits, permission);
  }

  return false;
};

export const isMorePrivileged = (roleA: Role, roleB: Role): boolean => {
  const roleAInherits = roles[roleA].inherits;

  if (roleAInherits === roleB) {
    return true;
  }

  if (roleAInherits) {
    return isMorePrivileged(roleAInherits, roleB);
  }

  return false;
};

export const hasRole = (role: Role, requiredRole: Role): boolean => {
  const roleSpec: RoleSpec = roles[role];
  if (!roleSpec) {
    return false;
  }

  if (role === requiredRole) {
    return true;
  } else if (roleSpec.inherits !== null) {
    return hasRole(roleSpec.inherits, requiredRole);
  }
  return false;
};

export { Role };
